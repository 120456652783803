import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import RouteIndex from "./routes/index";
import Header from "./component/inc/Header";
import Footer from "./component/inc/Footer";
import MayRenderNav from "./component/inc/MayRenderNav";
import { LocalStorage } from "./utils/HelperFunction";
import PublicRoute from "./routes/publicRoute";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "./store/userSlice";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { fetchMyNetwork } from "./store/networkSlice";
import { fetchMajor } from "./store/majorSlice";
import Loader from "./component/pages/customcomonents/Loader";
import PageLoader from "./component/pages/customcomonents/PageLoader";

function ScrollToTopMain() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
const firebaseConfig = {
  apiKey: "AIzaSyDNpfJ2_J_APsPN4f66tfKMHe6rCUKvjzw",
  authDomain: "briddg-28d48.firebaseapp.com",
  projectId: "briddg-28d48",
  storageBucket: "briddg-28d48.appspot.com",
  messagingSenderId: "1056914461585",
  appId: "1:1056914461585:web:201d80a25cb596990f4030",
  measurementId: "G-ZCBWR2R1Q2",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

function App() {
  const dispatch = useDispatch();
  const [pathname, setPathname] = useState(window.location.pathname);
  const user = useSelector((state) => state.User);
  const token = LocalStorage.get("token");
  useEffect(() => {
    requestNotificationPermission();
    dispatch(fetchMajor());
  }, []);
  useEffect(() => {
    setPathname(window.location.pathname);
  }, [window.location.pathname]);
  useEffect(() => {
    if (token) {
      dispatch(fetchUserData());
      dispatch(fetchMyNetwork());
    }
  }, [token]);
  useEffect(() => {
    onMessage(messaging, (payload) => {
      // console.log(payload);
      const notificationTitle = payload.data.title;
      const notificationOptions = {
        body: payload.data.body,
        icon: payload.data.icon,
      };

      if (!("Notification" in window)) {
        console.log("This browser does not support system notifications");
      } else if (Notification.permission === "granted") {
        new Notification(notificationTitle, notificationOptions);
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission(function (permission) {
          // console.log(permission);
          if (permission === "granted") {
            new Notification(notificationTitle, notificationOptions);
          }
        });
      }
    });
  }, []);
  async function requestNotificationPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BErj-WmYhQXcsxBZtgYyB1UjbDo2_n86O0fuJm8uz51xxhFs8H4X-DhMS7pAQ897HTr4fp2OpLVD5FMPwfAJU-o",
      });
      LocalStorage.set("device_token", token);
    }
  }
  return (
    <Router>
      <Suspense fallback={<PageLoader />}>
        <ScrollToTopMain />
        <Header />
        {token ? (
          user?.role ? (
            <RouteIndex />
          ) : (
            <Loader />
          )
        ) : (
          <PublicRoute setPathname={setPathname} />
        )}
        <MayRenderNav>
          <Footer />
        </MayRenderNav>
      </Suspense>
    </Router>
  );
}

export default App;
