import React, { useState } from "react";
import { Button, Form, Image, InputGroup } from "react-bootstrap";
import envelope from "../../images/landingpages/envelope.svg";
import SubscriptionModal from "./SubscriptionModal";
import { Helper } from "../../../utils/HelperFunction";
import { API_URL } from "../../../utils/API_URL";
import toast from "react-hot-toast";

const LandingInvitation = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const handleShow = () => {
    setShow(!show);
  };
  const handleSubmit = async () => {
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(email);
      if (!isValid) {
        return setError("Please enter valid email address");
      }
      const obj = {
        email,
      };
      const url = API_URL.EMAIL_INVITATION;
      const result = await Helper.PostData(url, obj);
      console.log(999, result.data);
      if (result.data?.status) {
        handleShow();
      } else {
        toast.error(result?.response?.data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <section className="newsletter">
        <div className="container">
          <div className="newsletter-inner">
            <h2>
              Be Among the First to Experience <b>BRiDDG</b>
            </h2>
            <p>
              Excited to start your journey with BRiDDG? Sign up now to request
              an early invitation and be the first to explore the possibilities
              that await. Your future is just a click away!
            </p>
            <InputGroup InputGroup className="mb-3">
              <span className="input-group-icon">
                <Image src={envelope} />
              </span>
              <Form.Control
                type="email"
                placeholder="Enter your email address"
                onChange={(e) => {
                  setError(false);
                  setEmail(e.target.value);
                }}
              />
              <Button variant={null} id="sendInvitation" onClick={handleSubmit}>
                Request an Early Invitation
              </Button>
            </InputGroup>
            {error && <div className="text-white">{error}</div>}
          </div>
        </div>
      </section>
      <SubscriptionModal show={show} handleClose={handleShow} />
    </>
  );
};

export default LandingInvitation;
