import React  from "react";
import mailsvg from "../../images/landingpages/mailsvg.svg";
import {Button, Image, Modal } from "react-bootstrap";
import {Icon} from "@iconify/react";
const SubscriptionModal = ({show,handleClose}) => {
    return(
        <React.Fragment>
             <Modal show={show} onHide={handleClose} centered className="subscribeModal" id="subscribeModal">
        <Modal.Body>
          <Button variant className="btnclose" onClick={handleClose}><Icon icon="mingcute:close-fill"/></Button>
				<Image src={mailsvg}/>
				<h2>Thank you for subscribing!</h2>
				<p>We're thrilled to have you on board. Get ready for exclusive updates and special offers coming your way soon!</p>
        </Modal.Body>
             </Modal>
        </React.Fragment>
    )
}
export default SubscriptionModal;