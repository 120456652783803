export const API_URL = {
  REGISTER: `user/signup`,
  VERIFY_OTP: `user/verify-otp`,
  LOGIN: `user/login`,
  RESET_PASSWORD: `user/reset-password`,
  RESEND_OTP: `user/resend-otp`,
  FORGOT_PASSWORD: `user/forgot-password`,
  CHANGE_PASSWORD: `user/change-password`,
  FETCH_PROFILE: `user/get-user-profile`,
  SEARCH_RESULT: `user/search-result`,
  ACCEPT_REQUEST: `user/accept-request`,
  CANCEL_REQUEST: `user/cancel-request`,
  CANCEL_SENT_REQUEST: `user/cancel-sent-request`,
  REMOVE_FRIEND: `user/remove-friend`,
  BLOCK_FRIEND: `user/block-friend`,
  REMOVE_GROUP_USER: `user/remove-group-user`,
  JOIN_GROUP: `user/join-group`,
  UNBLOCK_USER: `user/unblock-user`,
  CREATE_GROUP: `user/create-group`,
  FETCH_GROUPS: `user/get-groups`,
  FETCH_CONNECTIONS: `user/get-connections`,
  FETCH_SUGGESTED_GROUPS: `user/get-suggested-groups`,
  FETCH_ALL_CHATS: `user/get-chats`,
  DELETE_GROUP: `user/delete-group`,
  GET_NOTIFICATIONS: `user/get-notifications`,
  DELETE_NOTIFICATIONS: `user/delete-notifications`,
  GET_NOTIFICATION_COUNT: `user/unread-notification`,
  UPDATE_NOTIFICATION_COUNT: `user/update-unread-notification`,
  GET_ALL_USER_LIS: `user/get-all-user-list`,
  GLOBALSEARCH: `user/globalsearch`,
  UPDATE_USER: `user/update-user`,
  FETCH_INVITATION: `user/all-received-request`,
  FETCH_MYNETWORK: `user/all-friends`,
  GET_SUGGESTIONS: `user/get-suggestions`,
  SEND_REQUEST: `user/send-request`,
  FETCH_ALL_SENT_REQUEST: `user/all-sent-request`,
  SEND_MESSAGE: `user/send-message`,
  FETCH_CHAT_MESSAGES: `user/get-chat-message`,
  FETCH_UNREAD_MESSAGES: `user/unread-messages`,
  UPDATE_UNREAD_MESSAGES: `user/update-unread-messages`,
  CREATE_NEW_CHAT: `user/create-chat`,
  UPDATE_GROUP: `user/update-group`,
  ACCEPT_GROUP_REQUEST: `user/accept-group-request`,
  REJECT_GROUP_REQUEST: `user/reject-group-request`,
  GROUP_MEDIA: `user/get-group-media`,
  DELETE_CHAT: `user/delete-chat`,
  GET_ALL_POSTS: `post/get-posts`,
  GET_POST_DETAILS: `post/get-post-details`,
  CREATE_POSTS: `post/create-post`,
  LIKE_POSTS: `post/like`,
  POST_COMMENT: `post/comment`,
  GET_ALL_COMMENTS: `post/get-comments`,
  GET_ALL_REPLIES: `post/get-replies`,
  LIKE_COMMENT: `post/like-comment`,
  UNLIKE_COMMENT: `post/unlike-comment`,
  FETCH_LIKED_USERLIST: `post/get-liked-users`,
  DELETE_COMMENT: `post/delete-comment`,
  UNLIKE_POSTS: `post/unlike-post`,
  DELETE_POSTS: `post/delete-post`,
  UPDATE_POST: `post/update-post`,
  REPORT_POSTS: `post/report-post`,
  REPORT_COMMENT: `post/report-comment`,
  SHARE_POSTS: `post/share-post`,
  CREATE_PASSION: `home/create-passion`,
  UPDATE_PASSION: `home/update-passion`,
  GET_PASSIONS: `home/get-all-passion`,
  GET_PASSION_DETAILS: `home/get-passion`,
  DELETE_PASSION: `home/delete-passion`,
  GET_PRIVACY: `home/get-privacy`,
  COLLEGE_LIST: `home/college-list`,
  GLOBAL_DATA_LIST: `global/get-global-data`,
  GLOBAL_FAQ_LIST: `global/get-faq`,
  GLOBAL_RESOURCE_LIST: `global/get-resource`,
  SCHOOL_LIST: `global/get-school`,
  QUESTION_LIST: `global/get-question-list`,
  EMAIL_INVITATION: `global/email-invitation`,
  CREATE_PAGE: `officer/create-page`,
  UPDATE_PAGE: `officer/update-page`,
  GET_PAGE: `officer/get-page`,
  DELETE_PAGE: `officer/delete-page`,
  FOLLOW_PAGE: `officer/follow-page`,
  UNFOLLOW_PAGE: `officer/unfollow-page`,
  FOLLOW_USER: `officer/follow-user`,
  UNFOLLOW_USER: `officer/unfollow-user`,
  STUDENTS_SUGGESTION_LIST: `officer/student-suggestions-follow`,
  FOLLOWER_LIST: `officer/follower-list`,
  MAJOR_LIST: `user/major`,
  GET_SIGNED_URL: `user/signed-url`,
  REFRESH_TOKEN: `user/refreshtoken`,
  GET_RESULT: `user/results`,
  GET_CAREERS: `user/careers`,
  CAREER_REPORT: `user/careers-solutions`,
};
