import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Image, Navbar, Nav, Button, Container, Form } from "react-bootstrap";
import Logo from "../images/logo.png";
import UserImg from "../images/user.png";
import notifications from "../images/notifications.svg";
import message from "../images/message.svg";
import { Icon } from "@iconify/react";

import {
  Helper,
  LocalStorage,
  S3_URL,
  capitalizeEveryWord,
  formatTimeAgo,
} from "../../utils/HelperFunction";
import { useDispatch, useSelector } from "react-redux";
import { LogedOut, fetchUserData } from "../../store/userSlice";
import { useSocket } from "../../store/SocketContext";
import {
  setNotification,
  setOnlineUsers,
  clearNotification,
} from "../../store/socketSlice";
import { SocketEvent } from "../../utils/Constants";
import { API_URL } from "../../utils/API_URL";
import {
  fetchNotifications,
  fetchUnreadNotification,
  updateUnreadNotification,
} from "../../store/NotificationSlice";
import {
  fetchUnreadMessage,
  updateUnreadMessage,
} from "../../store/messageNotificationSlice";
import toast from "react-hot-toast";
import { set } from "lodash";

const MESSAGE_RECEIVED_EVENT = "newMessage";
const Header = () => {
  const { socket } = useSocket();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { unreadNotification } = useSelector((state) => state.Notification);
  const userDetails = useSelector((state) => state.User);
  const notification = useSelector((state) => state.Socket.notification);
  const { unreadMessage } = useSelector((state) => state.UnreadMsg);
  const { notificationList } = useSelector((state) => state.Notification);
  const { pathname } = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const [userList, setUserList] = useState(null);
  const [msgNotification, setMsgNotification] = useState(0);
  const [showMsg, setShowMsg] = useState(false);
  const [showList, setShowList] = useState(false);
  const showRef = useRef(null);

  useEffect(() => {
    setMsgNotification(unreadMessage?.length || 0);
  }, [unreadMessage]);
  useEffect(() => {
    console.log("location--->", pathname);
    setSearchTerm(null);
  }, [pathname]);
  console.log("location--->11", searchTerm);
  useEffect(() => {
    LocalStorage.get("token") && fetchUserList();
  }, [searchTerm]);
  useEffect(() => {
    if (LocalStorage.get("token")) {
      dispatch(fetchNotifications());
      dispatch(fetchUnreadMessage());
      dispatch(fetchUnreadNotification());
    }
  }, [LocalStorage.get("token")]);
  const handleClickUser = (e, id, type) => {
    setSearchTerm("");
    setShowList(false);
    navigate(type === "group" ? `/groups/detail?key=${id}` : `/profile/${id}`);
  };

  const acceptFriendRequest = async (e, id) => {
    e.preventDefault();
    try {
      const url = API_URL.ACCEPT_REQUEST;
      const result = await Helper.PostData(url, { user_id: id });
      if (result.data?.status) {
        dispatch(updateUnreadNotification({ minus: true }));
        dispatch(fetchUserData());
        dispatch(fetchNotifications());
        setShowMsg(true);
        setTimeout(() => {
          setShowMsg(false);
        }, 1500);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const ignoreRequest = async (e, id) => {
    e.preventDefault();
    try {
      const url = API_URL.CANCEL_REQUEST;
      const result = await Helper.PostData(url, { user_id: id });
      if (result.data?.status) {
        dispatch(updateUnreadNotification({ minus: true }));
        dispatch(fetchUserData());
        dispatch(fetchNotifications());
      }
    } catch (err) {
      console.error(err);
    }
  };
  const fetchUserList = async () => {
    try {
      if (searchTerm === null || searchTerm?.trim()?.length === 1) return;
      const url = `${API_URL.GLOBALSEARCH}?name=${searchTerm}`;
      const result = await Helper.GetData(url);
      if (result.data?.status) {
        setUserList(result.data.data);
        setShowList(true);
      } else {
        setUserList([]);
      }
    } catch (err) {
      console.error(err);
      setUserList([]);
    }
  };
  const onMessageReceived = (message) => {
    if (pathname !== "/chat") {
      dispatch(updateUnreadMessage({ group_id: message.payload.group_id }));
    }
  };
  const likePost = () => {
    dispatch(updateUnreadNotification({ reset: false }));
    dispatch(fetchNotifications());
  };
  const commentPost = (message) => {
    dispatch(updateUnreadNotification({ reset: false }));
  };
  const acceptRequest = (message) => {
    dispatch(setNotification(message));
    dispatch(fetchNotifications());
  };
  const friendRequest = () => {
    dispatch(updateUnreadNotification({ reset: false }));
    dispatch(fetchNotifications());
  };
  const canceledSentRequest = () => {
    dispatch(updateUnreadNotification({ minus: true }));
    dispatch(fetchNotifications());
  };
  const onConnected = () => {
    console.log("connected");
  };
  const onDisconnected = () => {
    console.log("disconnected");
  };
  const handleChatToggle = () => {
    dispatch(fetchUserData());
  };
  const onlineUser = (user_id) => {
    // console.log("onlineusers", user_id);
    dispatch(setOnlineUsers(user_id));
  };
  useEffect(() => {
    if (!socket) return;
    socket.on(SocketEvent.LIKE_POST_EVENT, likePost);
    socket.on(SocketEvent.MENTIONED_YOU_IN_CHAT, likePost);
    socket.on(SocketEvent.SEND_FRIEND_REQUEST_EVENT, friendRequest);
    socket.on(SocketEvent.COMMENT_POST_EVENT, commentPost);
    socket.on(SocketEvent.CONNECTED_EVENT, onConnected);
    socket.on(SocketEvent.ACCEPT_REQUEST_EVENT, acceptRequest);
    // socket.on(SocketEvent.GROUP_MEMBER_ADDED, likePost);
    socket.on(
      SocketEvent.CANCEL_SENT_FRIEND_REQUEST_EVENT,
      canceledSentRequest
    );
    socket.on("onlineUsers", onlineUser);
    socket.on(MESSAGE_RECEIVED_EVENT, onMessageReceived);
    socket.on(SocketEvent.LIKE_COMMENT_EVENT, likePost);
    socket.on(SocketEvent.CHAT_TOGGLE, handleChatToggle);
    return () => {
      socket.removeAllListeners();
      socket.off("onlineUsers", onlineUser);
      socket.off(
        SocketEvent.CANCEL_SENT_FRIEND_REQUEST_EVENT,
        canceledSentRequest
      );
      socket.off(SocketEvent.MENTIONED_YOU_IN_CHAT, likePost);
      socket.off(SocketEvent.SEND_FRIEND_REQUEST_EVENT, friendRequest);
      socket.off(MESSAGE_RECEIVED_EVENT, onMessageReceived);
      socket.off(SocketEvent.ACCEPT_REQUEST_EVENT, acceptRequest);
      socket.off(SocketEvent.CONNECTED_EVENT, onConnected);
      socket.off(SocketEvent.DISCONNECTED_EVENT, onDisconnected);
      socket.off(SocketEvent.LIKE_POST_EVENT, likePost);
      socket.off(SocketEvent.LIKE_COMMENT_EVENT, likePost);
      socket.off(SocketEvent.CHAT_TOGGLE, handleChatToggle);
      // socket.off(SocketEvent.GROUP_MEMBER_ADDED, likePost);
    };
  }, [socket]);
  const handleDropdownToggle = (e) => {
    e.preventDefault();
    setShowDropdown2(false);
    setShowDropdown(!showDropdown);
  };
  const dropdownRef = useRef(null);
  const headerdropdownRef = useRef(null);

  const handleDropdownToggle2 = (e) => {
    e.preventDefault();
    setShowDropdown(false);
    setShowDropdown2(!showDropdown2);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      event.target.getAttribute("name") !== "notifications" &&
      !dropdownRef.current.contains(event.target)
    ) {
      setShowDropdown2(false);
    }
    if (
      headerdropdownRef.current &&
      event.target.getAttribute("name") !== "notifications" &&
      !headerdropdownRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    // dispatch(fetchMajor());
    // Attach the event listener on component mount
    document?.addEventListener("mousedown", handleClickOutside);

    // Detach the event listener on component unmount
    return () => {
      document?.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleLogout = () => {
    setShowDropdown(false);
    dispatch(LogedOut());
    LocalStorage.clear();
  };
  useEffect(() => {
    setUrl(pathname);
    if (pathname === "/chat") {
      dispatch(updateUnreadMessage({ reset: true }));
    }
  }, [pathname]);
  const data = [
    {
      pageName: "/",
      title: "Home",
      icon: <Icon icon="mdi-light:home" />,
    },
    {
      pageName: "/my-network",
      title: "My Network",
      icon: <Icon icon="tabler:users" />,
    },
    {
      pageName: "/groups",
      title: "Groups",
      icon: <Icon icon="clarity:users-line" />,
    },
    {
      pageName: "/my-passion-project",
      title: "Passion Projects",
      icon: <Icon icon="bx:file" />,
    },
    {
      pageName: `/profile/${userDetails?._id}`,
      title: "Profile",
      icon: <Icon icon="ep:user" />,
    },
    {
      pageName: "/notifications",
      title: "Notifications",
      icon: <Icon icon="ion:notifications-outline" />,
    },
    {
      pageName: "/discover",
      title: "Discover",
      icon: <Icon icon="tabler:users" />,
    },
  ];
  const handleNotification = () => {
    dispatch(clearNotification());
    setShowDropdown2(false);
    return navigate("/notifications");
  };
  const handleSearchResult = (event) => {
    if (searchTerm?.trim().length < 3) {
      return;
    }
    if (event.key === "Enter") {
      navigate(`/search?search=${searchTerm}`);
      setShowList(false);
    }
  };
  const handleGroupJoinRequest = async (
    event,
    notification_id,
    group_id,
    type
  ) => {
    event.preventDefault();
    try {
      const url =
        type === "accept"
          ? API_URL.ACCEPT_GROUP_REQUEST
          : API_URL.REJECT_GROUP_REQUEST;

      const result = await Helper.PatchData(url, { notification_id, group_id });
      dispatch(fetchNotifications());
      // fetchNotification();
    } catch (err) {
      console.error(err);
    }
  };
  const handleBlur = (e) => {
    setTimeout(() => {
      setShowList(false);
    }, 300);
  };
  // console.log(555, { showList });
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-primary" sticky="top">
      <Container className="position-relative">
        <Navbar.Brand as={Link} to="/">
          <Image src={Logo} width="160" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        {/* {LocalStorage.get("token") && ( */}
        {LocalStorage.get("token") ? (
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto d-lg-none">
              {data.map((item, index) => (
                <Nav.Link
                  key={index}
                  className={url === item.pageName ? " active" : ""}
                  as={Link}
                  to={item.pageName}
                >
                  {" "}
                  {item.icon}
                  {item.title}
                </Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        ) : (
          <Navbar.Collapse id="navbarScroll" className="navbarmarginright">
            <Nav className="mx-auto ">
              <Nav.Link as={NavLink} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={NavLink} to="/howitworks">
                How It Works
              </Nav.Link>
              <Nav.Link as={NavLink} to="/aboutus">
                About Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        )}

        <div className="rightSide">
          {LocalStorage.get("token") && (
            <div className="headersearch position-relative">
              <Form.Control
                type="text"
                autoComplete="new-password"
                placeholder="Search"
                name="searchTerm"
                value={searchTerm || ""}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleSearchResult}
                onBlur={handleBlur}
              />
              <svg
                className="position-absolute"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                >
                  <circle cx="11" cy="11" r="8" />
                  <path d="m21 21l-4.3-4.3" />
                </g>
              </svg>
              {showList && userList && userList.length > 0 && (
                <div className="search-dropdown" ref={showRef} id="showList">
                  {userList.map((user) => (
                    <span
                      className="drop_down_list"
                      onClick={(e) => handleClickUser(e, user._id, user.type)}
                    >
                      <strong>
                        {capitalizeEveryWord(
                          user.name
                            ? user.name
                            : user.first_name + " " + user.last_name
                        )}
                      </strong>{" "}
                      {user.email}
                    </span>
                  ))}
                </div>
              )}
            </div>
          )}
          {LocalStorage.get("token") ? (
            <div className="btnBox header_user_image">
              {/* <div className="ellipseDiv_parent" onClick={handleNotification}>
                <Image
                  width="38px"
                  height="38px"
                  src={notifications}
                  roundedCircle
                />
                {notification.length > 0 && (
                  <div className="ellipseDiv">{notification.length}</div>
                )}
              </div> */}
              <div className="ellipseDiv_parent">
                <Image
                  width="38px"
                  height="38px"
                  name="notifications"
                  src={notifications}
                  roundedCircle
                  onClick={handleDropdownToggle2}
                />
                {unreadNotification > 0 && (
                  <div className="ellipseDiv ellipseDivnotify">
                    {unreadNotification < 9 ? unreadNotification : 9}
                    {unreadNotification > 9 && <sup>+</sup>}
                  </div>
                )}
                {showDropdown2 && (
                  <div className="notification_dropdown" ref={dropdownRef}>
                    <div>
                      {showMsg && (
                        <div className="notificationlist bg-light p-1">
                          <div className="notificationcontent flex-column">
                            Friend Request Accepted
                          </div>
                        </div>
                      )}
                      {notification &&
                        notification.length > 0 &&
                        notification.map(
                          (noti, index) =>
                            index < 3 && (
                              <div className="notificationlist bg-light p-1">
                                <div className="notificationimg">
                                  <Image
                                    src={
                                      noti.sender?.profile_pic
                                        ? S3_URL + noti.sender.profile_pic
                                        : UserImg
                                    }
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                                <div className="notificationcontent flex-column">
                                  <div className="w-100 d-flex justify-content-between">
                                    <h3>
                                      {noti.sender?.name &&
                                        capitalizeEveryWord(noti.sender?.name)}
                                      <span>{noti.payload?.message}</span>
                                    </h3>
                                    <span className="notificationtime">
                                      {formatTimeAgo(noti.payload?.updatedAt)}
                                    </span>
                                  </div>
                                  {noti.payload?.message ===
                                    " sent you friend request" && (
                                    <div className="accept-decline mt-1 w-100 text-start">
                                      <a
                                        className="acceptbtn"
                                        onClick={(e) =>
                                          acceptFriendRequest(
                                            e,
                                            noti.payload.from_user_id
                                          )
                                        }
                                      >
                                        Accept
                                      </a>
                                      <a href="#" className="rejectbtn">
                                        Reject
                                      </a>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )
                        )}

                      {(!notification || notification.length < 3) &&
                        notificationList &&
                        notificationList.length > 0 &&
                        notificationList.map(
                          (noti, index) =>
                            index < 3 - notification.length && (
                              <div className="notificationlist" key={index}>
                                <div className="notificationimg">
                                  <Image
                                    src={
                                      noti.sender?.profile_pic
                                        ? S3_URL + noti.sender.profile_pic
                                        : UserImg
                                    }
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                                <div className="notificationcontent flex-column">
                                  <div
                                    className="w-100 d-flex justify-content-between"
                                    onClick={handleNotification}
                                  >
                                    <h3>
                                      {noti.sender?.first_name &&
                                        capitalizeEveryWord(
                                          noti.sender?.first_name +
                                            " " +
                                            noti.sender?.last_name
                                        )}
                                      <span>{noti?.message}</span>
                                    </h3>
                                    <span className="notificationtime">
                                      {formatTimeAgo(noti.createdAt)}
                                    </span>
                                  </div>
                                  {noti.type === "FRIENDREQUEST" ? (
                                    <div className="accept-decline mt-1 w-100 text-start">
                                      <a
                                        href="#"
                                        className="acceptbtn"
                                        onClick={(e) =>
                                          acceptFriendRequest(
                                            e,
                                            noti?.payload?.from_user_id
                                          )
                                        }
                                      >
                                        Accept
                                      </a>
                                      <a
                                        href="#"
                                        className="rejectbtn"
                                        onClick={(e) =>
                                          ignoreRequest(
                                            e,
                                            noti?.payload?.from_user_id
                                          )
                                        }
                                      >
                                        Reject
                                      </a>
                                    </div>
                                  ) : noti.type === "GROUPMEMBERADDED" ? (
                                    <div className="accept-decline mt-1 w-100 text-start">
                                      <a
                                        href="#"
                                        className="acceptbtn"
                                        onClick={(e) =>
                                          handleGroupJoinRequest(
                                            e,
                                            noti?._id,
                                            noti?.payload?._id,
                                            "accept"
                                          )
                                        }
                                      >
                                        Accept
                                      </a>
                                      <a
                                        href="#"
                                        className="rejectbtn"
                                        onClick={(e) =>
                                          handleGroupJoinRequest(
                                            e,
                                            noti?._id,
                                            noti?.payload?._id,
                                            "reject"
                                          )
                                        }
                                      >
                                        Reject
                                      </a>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            )
                        )}
                    </div>
                    <div className="seeallnotify" onClick={handleNotification}>
                      {(notificationList.length > 0 ||
                        notification.length > 0) &&
                        "See All Notifications"}
                    </div>
                    {notificationList?.length < 1 &&
                      notification?.length < 1 && (
                        <div className="seeallnotify text-center">
                          No Notification Found
                        </div>
                      )}
                  </div>
                )}
              </div>
              <div
                className="ellipseDiv_parent"
                onClick={() =>
                  userDetails?.is_chat_active
                    ? navigate("/chat")
                    : toast.error("Please contact administrator to enable chat")
                }
              >
                <Image
                  style={{ opacity: userDetails?.is_chat_active ? 1 : 0.6 }}
                  width="38px"
                  height="38px"
                  src={message}
                  roundedCircle
                />{" "}
                {msgNotification > 0 && userDetails?.is_chat_active && (
                  <div className="ellipseDiv">
                    {msgNotification < 9 ? msgNotification : 9}{" "}
                    {msgNotification > 9 && <sup>+</sup>}
                  </div>
                )}
              </div>
              <Image
                width="38px"
                height="38px"
                name="notifications"
                src={
                  userDetails.profile_pic
                    ? S3_URL + userDetails.profile_pic
                    : UserImg
                }
                roundedCircle
                onClick={handleDropdownToggle}
              />

              {showDropdown && (
                <div className="header_dropdown" ref={headerdropdownRef}>
                  <div
                    className="flex_item"
                    onClick={(e) => {
                      handleDropdownToggle(e);
                      return navigate(`/profile/${userDetails?._id}`);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="32"
                        d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96"
                      />
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit="10"
                        strokeWidth="32"
                        d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304Z"
                      />
                    </svg>
                    {/* <Image src={person} width={25} /> */}
                    <span>Profile</span>
                  </div>
                  <div className="flex_item" onClick={handleLogout}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="32"
                        d="M304 336v40a40 40 0 0 1-40 40H104a40 40 0 0 1-40-40V136a40 40 0 0 1 40-40h152c22.09 0 48 17.91 48 40v40m64 160l80-80l-80-80m-192 80h256"
                      />
                    </svg>
                    {/* <Image src={logout} width={25} /> */}
                    <span>Logout</span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="btnBox">
              <Button
                variant="outline-primary"
                as={Link}
                to="/login"
                className="me-2"
              >
                Login
              </Button>
              <Button as={Link} to="/signup" variant="primary">
                Sign Up
              </Button>
            </div>
          )}
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;
