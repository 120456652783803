import React, { useState, useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

import shape1 from "../images/landingpages/shape1.svg";
import banner1 from "../images/landingpages/banner-1.webp";
import ellipse from "../images/landingpages/ellipse.png";
import aboutimg1 from "../images/landingpages/about-img1.webp";
import HomepageVideo from "../images/landingpages/HomepageVideo.mp4";
import serviceicon1 from "../images/landingpages/serviceicon1.svg";
import serviceicon2 from "../images/landingpages/serviceicon2.svg";
import serviceicon3 from "../images/landingpages/serviceicon3.svg";
import serviceicon4 from "../images/landingpages/serviceicon4.svg";
import men1 from "../images/landingpages/men-1.webp";
import PageLoader from "./customcomonents/PageLoader";
import LandingInvitation from "./customcomonents/LandingInvitation";
const AboutUs = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const slides = ["The Story of BRiDDG", "Welcome to BRiDDG"];

  // Automatically change slides every 4 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [slides.length]);
  return (
    <React.Fragment>
      <div className="landingpages">
        {true ? (
          <>
            <section className="banner">
              <div className="shapes shape1">
                <Image src={shape1} className="img-fluid" />
              </div>
              <Container>
                <Row className="align-items-center">
                  <Col lg={6} md={6}>
                    <div className="banner-content">
                      <h2>About Us</h2>
                      <div className="topbar_text_slider mb-3">
                        <ul className="custom-slider">
                          {slides.map((slide, index) => (
                            <li
                              key={index}
                              className={`slider-item ${
                                index === activeIndex ? "active" : ""
                              }`}
                            >
                              {slide}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <p>
                        A platform where your unique story takes center stage in
                        the college admissions journey.
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} md={6} className="text-center">
                    <div className="banner-img d-inline-block position-relative">
                      <Image
                        src={banner1}
                        className="img-fluid max-height-550"
                      />
                      <div className="icon-ellipse">
                        <Image src={ellipse} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
              <div className="shapes shape2">
                <img src={shape1} className="img-fluid" />
              </div>
            </section>
            <section className="empowering-aboutus pt-0">
              <Container className="container-big">
                <Row className="align-items-center">
                  <Col lg={6} md={6} className="pe-lg-5">
                    <div className="empowering-aboutus-img">
                      <Image src={aboutimg1} className="img-fluid" />
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="aboutus-content">
                      <div className="heading mb-3">
                        <h2>
                          Our <span>Mission</span>
                        </h2>
                      </div>
                      <p>
                        At BRiDDG, we are driven by a singular mission: to
                        transform the college admissions landscape. We believe
                        that a student's potential cannot be fully captured by
                        essays or standardized tests alone. Our platform is
                        designed to highlight what truly matters – your talents,
                        passions, and the distinct qualities you bring to the
                        table. We're here to shift the focus from traditional,
                        often restrictive metrics to a more comprehensive
                        representation of who you are as an individual.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="strengthening-aboutus">
              <Container>
                <Row className="align-items-center">
                  <Col lg={6} md={6}>
                    <div className="aboutus-content">
                      <div className="heading mb-3">
                        <h2>
                          Our <span>Vision</span>
                        </h2>
                      </div>
                      <p>
                        We envision a world where college admissions are not
                        about fitting into a mold but breaking free from it.
                        BRiDDG is more than just a platform; it's a movement
                        towards celebrating diversity and individuality in the
                        educational journey.
                      </p>{" "}
                      <p>
                        No more carbon copies, no more checking boxes without
                        showcasing your true self. It's time for you to write
                        your own narrative.
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} md={6} className="ps-lg-5">
                    <div className="ps-0 strengthening-aboutus-img position-relative p-5">
                      <video autoPlay muted loop playsInline>
                        <source src={HomepageVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="our-services position-relative">
              <Container>
                <Row className="align-items-center">
                  <Col lg={5} md={5} sm={12} xs={12} className="mt-4 pe-lg-5">
                    <div className="service-img position-relative">
                      <div className="service-img-inner position-relative text-center mb-3">
                        <Image src={men1} className="img-fluid" />
                      </div>
                    </div>
                  </Col>
                  <Col lg={7} md={7} sm={12} xs={12}>
                    <div className="services-content">
                      <div className="heading text-start mb-3">
                        <h2>What We Offer</h2>
                      </div>
                      <p>
                        BRiDDG is your space to demonstrate not just academic
                        prowess, but your passions, your contributions to the
                        wider community, and the unique attributes you would
                        bring to a college or university. Our platform allows
                        you to:
                      </p>
                      <Row className="mt-4">
                        <Col lg={6} md={6} sm={6}>
                          <div className="service-grid mb-4">
                            <div className="service-icon d-inline-flex align-items-center justify-content-center">
                              <Image src={serviceicon1} />
                            </div>
                            <h3>Showcase Your Talents</h3>
                            <p>
                              Highlight your skills, projects, and achievements
                              in a dynamic and engaging way.
                            </p>
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                          <div className="service-grid mb-4">
                            <div className="service-icon d-inline-flex align-items-center justify-content-center">
                              <Image src={serviceicon2} />
                            </div>
                            <h3>Share Your Passions</h3>
                            <p>
                              Let colleges see what drives you, be it art,
                              technology, community service, or anything in
                              between.
                            </p>
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                          <div className="service-grid mb-4">
                            <div className="service-icon d-inline-flex align-items-center justify-content-center">
                              <Image src={serviceicon3} />
                            </div>
                            <h3>Demonstrate Community Impact</h3>
                            <p>
                              Share your contributions to your community,
                              showcasing your role as a proactive and engaged
                              citizen.
                            </p>
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                          <div className="service-grid mb-4">
                            <div className="service-icon d-inline-flex align-items-center justify-content-center">
                              <Image src={serviceicon4} />
                            </div>
                            <h3>Express Your Individuality</h3>
                            <p>
                              Present your unique story and personality, showing
                              colleges the person behind the application.
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
              <div
                className="shapes shape3"
                style={{ transform: "rotate(105deg)" }}
              >
                <img src={shape1} className="img-fluid" />
              </div>
            </section>
            <LandingInvitation />
          </>
        ) : (
          <PageLoader />
        )}
      </div>
    </React.Fragment>
  );
};

export default AboutUs;
