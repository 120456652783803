import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";

const HomeFilter = () => {
  const location = useLocation();
  const userDetails = useSelector((state) => state.User);
  const [url, setUrl] = React.useState(null);
  React.useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  const FilterData = [
    {
      id: 1,
      path: "/",
      icon: <Icon icon="mdi-light:home" />,
      title: "Home",
    },
    // ...(userDetails?.role?.role==="student"?[
    {
      id: 2,
      path: "/my-network",
      icon: <Icon icon="tabler:users" />,
      title: "My Network",
    },
    // ]:[]),
    // ...(userDetails?.role?.role==="student"?[]:[{
    //   id: 3,
    //   path: "/page",
    //   icon: <Icon icon="iconoir:multiple-pages" />,
    //   title: "Pages",
    // }]),
    ...(userDetails?.role?.role === "student"
      ? [
          {
            id: 4,
            path: "/groups",
            icon: <Icon icon="clarity:users-line" />,
            title: "Groups",
          },
        ]
      : []),
    ...(userDetails?.role?.role === "student"
      ? [
          {
            id: 6,
            path: "/my-passion-project",
            icon: <Icon icon="bx:file" />,
            title: "My Passion Projects",
          },
        ]
      : []),
    {
      id: 6,
      path: "/profile/",
      icon: <Icon icon="ep:user" />,
      title: "Profile",
    },

    {
      id: 8,
      path: "/notifications",
      icon: <Icon icon="ion:notifications-outline" />,
      title: "Notifications",
    },
    ...(userDetails?.role?.role === "student"
      ? [
          {
            id: 7,
            path: "/assessment",
            icon: <Icon icon="ic:outline-assessment" />,
            title: "Career Report",
          },
          {
            id: 8,
            path: "/discover",
            icon: <Icon icon="tabler:users" />,
            title: "Discover",
          },
        ]
      : []),

    {
      id: 9,
      path: "/resources",
      icon: <Icon icon="bx:file" />,
      title: "Resources",
    },
  ];
  return (
    url && (
      <div className="discoverBox discoverBox1 flex-lg-column d-none d-lg-flex">
        {FilterData.map((item, index) => {
          // console.log(667, {
          //   url,
          //   path: item.path,
          //   res: url.split("/")[1],
          //   result: (url === item.path || `/${url.split("/")[1]}` === item.path),
          // });
          return (
            <NavLink
              to={
                item.path === "/assessment" &&
                userDetails?.answers?.length === 60
                  ? `${item.path}/result`
                  : item.path === "/profile/"
                  ? item.path + userDetails._id
                  : item.path
              }
              state={item.path === "/profile/" ? { self: true } : null}
              key={index}
              className={`starpos  ${
                url === item.path || `/${url?.split("/")[1]}` === item.path
                  ? "active"
                  : ""
              }`}
            >
              {item.icon}
              {item.title}
            </NavLink>
          );
        })}
      </div>
    )
  );
};

export default HomeFilter;
