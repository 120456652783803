import React, { useState, useEffect } from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import signup1 from "../images/landingpages/signup1.png";
import download from "../images/landingpages/download.webp";
import download1 from "../images/landingpages/download1.webp";
import serviceicon1 from "../images/landingpages/serviceicon1.svg";
import serviceicon4 from "../images/landingpages/serviceicon4.svg";
import serviceicon5 from "../images/landingpages/serviceicon5.svg";
import serviceicon3 from "../images/landingpages/serviceicon3.svg";
import serviceicon2 from "../images/landingpages/serviceicon2.svg";
import Feeds1 from "../images/landingpages/Feeds1.png";
import arrowright from "../images/landingpages/arrow-right.svg";
import stepimg5 from "../images/landingpages/step-img5.webp";
import Community1 from "../images/landingpages/Community1.png";
import Connections1 from "../images/landingpages/Connections1.png";
import PageLoader from "./customcomonents/PageLoader";
import AOS from "aos";
import "aos/dist/aos.css";
import LandingInvitation from "./customcomonents/LandingInvitation";
const HowItWorks = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in ms
      easing: "ease-out", // Easing function
      once: false, // Animation will trigger each time it's scrolled into view
    });
  }, []);
  return (
    <React.Fragment>
      <div className="landingpages">
        {true ? (
          <>
            <section className="howitworks">
              <Container>
                <h1
                  className="howitworkshead mb-5"
                  data-aos="fade-up"
                  data-aos-once="false"
                >
                  How BRiDDG Works
                </h1>
                <Row
                  className="gx-0 align-items-end mb-5"
                  data-aos-once="false"
                  data-aos="fade-left"
                >
                  <Col md={12} lg={10}>
                    <div className="howitworksinner">
                      <Row className="align-items-center">
                        <Col md={6}>
                          <Image src={signup1} className="img-fluid" />
                        </Col>
                        <Col md={6}>
                          <div className="service-grid position-relative">
                            <span className="countno">1</span>
                            <div className="service-icon d-inline-flex align-items-center justify-content-center">
                              <Image src={serviceicon1} />
                            </div>
                            <h3>Easy Registration, Unique Profiles</h3>
                            <p>
                              Start your BRiDDG journey with an easy
                              registration process. Once in, you can personalize
                              your profile to reflect your unique interests and
                              extracurricular activities. This is your space to
                              display your talents, share your hobbies, and
                              exchange ideas that highlight your individuality.
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={12} lg={2} className="d-none d-lg-flex">
                    <Image
                      src={download}
                      className="img-fluid opacity01"
                      alt=""
                    />
                  </Col>
                </Row>
                <Row
                  className="gx-0 align-items-end mb-5"
                  data-aos-once="false"
                  data-aos="fade-right"
                >
                  <Col className="d-none d-lg-flex">
                    <Image
                      src={download1}
                      className="img-fluid opacity01 ms-auto"
                      alt=""
                    />
                  </Col>
                  <Col md={12} lg={10}>
                    <div className="howitworksinner">
                      <Row className="align-items-center">
                        <Col md={6}>
                          <div className="service-grid position-relative">
                            <span className="countno">2</span>
                            <div className="service-icon d-inline-flex align-items-center justify-content-center">
                              <Image src={serviceicon4} />
                            </div>
                            <h3>Spotlight Your Skills</h3>
                            <p>
                              You can showcase everything from your artistic
                              talents to scientific projects, athletic
                              achievements, and community involvement. This is
                              your space to share your journey and achievements,
                              making your unique skills visible to colleges and
                              peers.
                            </p>
                          </div>
                        </Col>
                        <Col md={6}>
                          <Image src={Feeds1} className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row
                  className="gx-0 align-items-end mb-5"
                  data-aos-once="false"
                  data-aos="fade-left"
                >
                  <Col md={12} lg={10}>
                    <div className="howitworksinner">
                      <Row className="align-items-center">
                        <Col md={6}>
                          <Image src={Connections1} className="img-fluid" />
                        </Col>
                        <Col md={6}>
                          <div className="service-grid position-relative">
                            <span className="countno">3</span>
                            <div className="service-icon d-inline-flex align-items-center justify-content-center">
                              <Image src={serviceicon2} />
                            </div>
                            <h3>Build Connections, Gain Insights</h3>
                            <p>
                              Engage with peers, educators, mentors and
                              admissions counselors for invaluable insights.
                              Utilize interactive tools and resources, including
                              college finders and scholarship searches, to
                              support your educational journey.
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={12} lg={2} className="d-none d-lg-flex">
                    <Image
                      src={download}
                      className="img-fluid opacity01"
                      alt=""
                    />
                  </Col>
                </Row>
                <Row
                  className="gx-0 align-items-end mb-5"
                  data-aos-once="false"
                  data-aos="fade-right"
                >
                  <Col className="d-none d-lg-flex">
                    <Image
                      src={download1}
                      className="img-fluid opacity01 ms-auto"
                      alt=""
                    />
                  </Col>
                  <Col md={12} lg={10}>
                    <div className="howitworksinner">
                      <Row className="align-items-center">
                        <Col md={6}>
                          <div className="service-grid position-relative">
                            <span className="countno">4</span>
                            <div className="service-icon d-inline-flex align-items-center justify-content-center">
                              <Image src={serviceicon3} />
                            </div>
                            <h3>Grow in a Supportive Community</h3>
                            <p>
                              Connect in interest groups with students sharing
                              your passions, enhancing collaboration and
                              connection. Participate in events like virtual
                              college fairs and workshops, gaining insights from
                              admissions experts to explore your options.
                            </p>
                          </div>
                        </Col>
                        <Col md={6}>
                          <Image src={Community1} className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row
                  className="gx-0 align-items-end mb-5"
                  data-aos-once="false"
                  data-aos="fade-left"
                >
                  <Col md={12} lg={10}>
                    <div className="howitworksinner">
                      <Row className="align-items-center">
                        <Col md={6}>
                          <Image src={stepimg5} className="img-fluid" />
                        </Col>
                        <Col md={6}>
                          <div className="service-grid position-relative">
                            <span className="countno">5</span>
                            <div className="service-icon d-inline-flex align-items-center justify-content-center">
                              <Image src={serviceicon5} />
                            </div>
                            <h3>
                              Ready To Kickstart Your Journey To College &
                              Beyond?
                            </h3>
                            <p>
                              Join BRiDDG today and be part of a growing
                              community of future change makers. Don't wait -
                              shape your future, your way!.
                            </p>
                            <Button className="mt-4">
                              Get Started{" "}
                              <span>
                                <Image src={arrowright} />
                              </span>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={12} lg={2} className="d-none d-lg-flex"></Col>
                </Row>
              </Container>
            </section>
            <LandingInvitation />
          </>
        ) : (
          <PageLoader />
        )}
      </div>
    </React.Fragment>
  );
};

export default HowItWorks;
